import logo from './logo.svg';
import database from './data.json';
import './App.css';
import React from 'react';
import moment from 'moment';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCLsU2DK4-lry7JO4VA0ggORuljropQVKw",
  authDomain: "keyword-60147.firebaseapp.com",
  projectId: "keyword-60147",
  storageBucket: "keyword-60147.appspot.com",
  messagingSenderId: "648859369023",
  appId: "1:648859369023:web:b24be769e37faacdc1028a",
  measurementId: "G-BFCFJXF3W9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instructions: false,
      statistics: false
    };
  }

  toggleInstructions = () => {
    this.setState({
      instructions: !this.state.instructions
    })
  }

  toggleStatistics = () => {
    this.setState({
      statistics: !this.state.statistics
    })
  }

  getContent = () => {
    if (this.state.instructions) {
        return <Instructions toggleInstructions={this.toggleInstructions}/>;
    }
    else if (this.state.statistics) {
        return <Statistics toggleStatistics={this.toggleStatistics}/>;
    }
    return <Board />;
}

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <Header toggleInstructions={this.toggleInstructions} toggleStatistics={this.toggleStatistics}/>
          {this.getContent()}
        </div>
      </React.Fragment>
    );
  }
}

class Header extends React.Component {
  render() {
    return (
      <div className="Header">
        <div className="MenuRight">
          {/* <button className="icon" onClick={this.props.toggleStatistics}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path fill="#BFBFBF" d="M16,11V3H8v6H2v12h20V11H16z M10,5h4v14h-4V5z M4,11h4v8H4V11z M20,19h-4v-6h4V19z"></path>
              </svg>
            </button> */}
        </div>
        <div className="MenuLeft">
          <button className="icon" onClick={this.props.toggleInstructions}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
              <path fill="#BFBFBF" d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path>
            </svg>
          </button>
        </div>
        <div className="Title">
          <span className="Guess">key</span>
          <span className="Hint">WORD</span>
        </div>
      </div>
    );
  }
}

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "keyWORD",
          text: this.props.shareText
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch((error) => {
          console.error("Something went wrong", error);
        });
    }
  };

  render() {
    return (
        <div className="Tooltip">
          {this.props.text}
          <div>
            {this.props.showShare ? <button className="ShareButton" onClick={this.handleClick}><b>SHARE</b></button>: null}
          </div>
        </div>
    );
  }
}

class Row extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: this.props.style,
      layout: this.props.layout,
      hint: this.props.hint,
      guess: this.props.guess,
      solution: this.props.solution
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    const g = (e.target.guess.value).toLowerCase()
    if (this.props.instr == "true") {
      if (g == this.state.solution.toLowerCase()) {
        if (this.state.solution == "chat") {
          this.setState({
            style: "correct",
            guess: g
          });
          } else {
          this.setState({
            style: "",
            guess: g
          });
        }
        this.props.next()
      }
    } else {
      if (g == this.state.solution.toLowerCase()) {
        this.setState({
          style: "correct",
          guess: g
        });
        this.props.setTooltip("Congratulations! Return tomorrow for a new word", true);
      } else {
        this.setState({
          style: "",
          guess: g
        });
        this.props.add(g)
      }
    } 
  }

  render() {
    if (this.state.style == "active"){
      if (this.state.layout == "left") {
        return (
          <div className="Row Active">
            <div className="WordLeft Guess">
              <form onSubmit={this.handleSubmit}>
                <input type="text" autoComplete="off" autoCorrect="off" autoCapitalize="none" name="guess" autoFocus />
              </form>
            </div>
            <div className="WordRight Hint">{this.state.hint}</div>
          </div>
        );
      } else {
        return (
          <div className="Row Active">
            <div className="WordLeft Hint">{this.state.hint}</div>
            <div className="WordRight Guess">
              <form onSubmit={this.handleSubmit}>
                <input type="text" autoComplete="off" autoCorrect="off" autoCapitalize="none" name="guess" autoFocus />
              </form>
            </div>
          </div>
        );
      }
    } else if (this.state.style == "correct"){
      if (this.state.layout == "left") {
        return (
          <div className="Row Correct">
            <div className="WordLeft">{this.state.guess}</div>
            <div className="WordRight Hint">{this.state.hint}</div>
          </div>
        );
      } else {
        return (
          <div className="Row Correct">
            <div className="WordLeft Hint">{this.state.hint}</div>
            <div className="WordRight">{this.state.guess}</div>
          </div>
        );
        }
      } else {
        if (this.state.layout == "left") {
          return (
            <div className="Row">
              <div className="WordLeft Guess">{this.state.guess}</div>
              <div className="WordRight Hint">{this.state.hint}</div>
            </div>
          );
        } else {
          return (
            <div className="Row">
              <div className="WordLeft Hint">{this.state.hint}</div>
              <div className="WordRight Guess">{this.state.guess}</div>
            </div>
          );
        }
      }
  }
}

class Board extends React.Component {
  constructor(props) {
    super(props);
    const startDate = moment(database.startDate, "DD/MM/YYYY");
    const currentDate = moment();
    const diff = currentDate.diff(startDate);
    const diffDuration = moment.duration(diff).days();
    if (localStorage.getItem("SavedState") != null) {
      var savedState = JSON.parse(localStorage.getItem("SavedState"));
      if (savedState.number != database.puzzles[diffDuration].number) {
        localStorage.clear()
        this.state = {
          number: database.puzzles[diffDuration].number,
          hints: database.puzzles[diffDuration].hints,
          layout: database.puzzles[diffDuration].layout,
          solution: database.puzzles[diffDuration].solution,
          guesses: [],
          i: 0,
          tooltip: "Type a word above to start",
          showShare: false,
          shareText: ""
        };
        var stateToSave = JSON.stringify(this.state);
        localStorage.setItem("SavedState", stateToSave);
      } else {
        this.state = savedState;
        // console.log(this.state)
      }
    } else {
      localStorage.clear()
      this.state = {
        number: database.puzzles[diffDuration].number,
        hints: database.puzzles[diffDuration].hints,
        layout: database.puzzles[diffDuration].layout,
        solution: database.puzzles[diffDuration].solution,
        guesses: [],
        i: 0,
        tooltip: "Type a word above to start",
        showShare: false,
        shareText: ""
      };
      var stateToSave = JSON.stringify(this.state);
      localStorage.setItem("SavedState", stateToSave);
    }
  }

  add = guess => {
    const g = this.state.guesses;
    g.push(guess);
    if (this.state.i < this.state.hints.length - 1) {
      this.setState({
        guesses: g,
        i: this.state.i + 1,
        tooltip: `You have ${this.state.hints.length - this.state.i - 1} attempt(s) left`
      }, () => {
        var stateToSave = JSON.stringify(this.state);
        localStorage.setItem("SavedState", stateToSave);
      });
    } else {
      this.setState({
        guesses: g,
        i: this.state.i + 1,
        tooltip: `Nice try! The solution was ${this.state.solution}`
      }, () => {
        var stateToSave = JSON.stringify(this.state);
        localStorage.setItem("SavedState", stateToSave);
      });
    }
    
  }

  setTooltip = (text, share) => {
    var shareText = "keyWORD #";
    shareText = shareText.concat(this.state.number, "\n");
    for(var i=0; i < this.state.i; i++){
      if (this.state.layout[i] == "left") {
        shareText = shareText.concat("◀︎☒");
      } else {
        shareText = shareText.concat("▶︎☒");
      }
    }
    if (this.state.layout[i] == "left") {
      shareText = shareText.concat("◀︎☑︎ ", i+1, "/5", "\nPlay on keyword.games");
    } else {
      shareText = shareText.concat("▶︎☑︎ ", i+1, "/5", "\nPlay on keyword.games");
    }
    this.setState({
      tooltip: text,
      showShare: share,
      shareText: shareText
    })
  }

  render() {

    const rows = [];

    for (var i = 0; i < this.state.i; i += 1) {
      rows.push(<Row style="" layout={this.state.layout[i]} hint={this.state.hints[i]} guess={this.state.guesses[i]} solution={this.state.solution}/>);
    };
    if (this.state.i < this.state.hints.length) {
      rows.push(<Row style="active" layout={this.state.layout[this.state.i]} hint={this.state.hints[i]} guess="" solution={this.state.solution} add={this.add} setTooltip={this.setTooltip}/>);
    }
    return (
      <div className="Board">
        {rows}
        <Tooltip text={this.state.tooltip} showShare={this.state.showShare} shareText={this.state.shareText}/>
      </div>
    );
  }
}

class Instructions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      i: 0
    };
  }

  next = () => {
    this.setState({
      i: this.state.i + 1
    });
  }

  render() {
    return (
      <div className="Instructions">
        <h2>Instructions</h2>
        <p>
        The goal of the game is to <b>find a word that pairs with every other word in the puzzle</b>. Think about wordplays, compound nouns, expressions… You have <b>five attempts</b> to guess the word, and after each attempt you will be given a new hint. The layout gives you an indication of whether the word usually goes before or after the hint. A new puzzle is released daily.
        </p>
        <h3>Tutorial</h3>
        <p>
        Say the first hint is <b>ROOM</b>. What words pair with <b>ROOM</b>? Maybe <b>LIVING</b> as in <b>LIVING ROOM</b>? Click on the bar to the left of the hint and type <i>living</i>. Then press enter.
        </p>
        <Row style="active" layout="left" hint="ROOM" instr="true" solution="living" next={this.next}/>
        {this.state.i > 0 ? <p>Unfortunately, the guess is incorrect. Now you get a second hint: <b>GROUP</b>. What word goes well with <b>GROUP</b> and <b>ROOM</b>? Maybe <b>MEETING</b> as in <b>MEETING ROOM</b> and <b>GROUP MEETING</b>? Type <i>meeting</i> below, then press enter.</p> : null}
        {this.state.i > 0 ? <Row style="active" layout="right" hint="GROUP" instr="true" solution="meeting" next={this.next}/> : null}
        {this.state.i > 1 ? <p>Still incorrect. You get a third hint: <b>COFFEE</b>. What word goes well with <b>GROUP</b>, <b>ROOM</b> and <b>COFFEE</b>? Maybe <b>CHAT</b> as in <b>CHAT ROOM</b>, <b>GROUP CHAT</b> and <b>COFFEE CHAT</b>? Type <i>chat</i> below, then press enter.</p> : null}
        {this.state.i > 1 ? <Row style="active" layout="right" hint="COFFEE" instr="true" solution="chat" next={this.next}/> : null}
        {this.state.i > 2 ? <p>Congratulations, you found the key<b>WORD</b>! <a onClick={this.props.toggleInstructions}><u>Close this window</u></a> to start playing.</p> : null}
        <h3>Credits</h3>
        <p>
        A game by <b>TM</b>. Have some words in mind? Suggest them <a href="https://forms.gle/uNy1hfhFNVutqBS18">here</a>. 
        </p>
      </div>
    );
  }
}

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamesPlayed: 0,
      gamesWon: 0,
      scores: [0,0,0,0,0]
    };
  }

  next = () => {
    this.setState({
      i: this.state.i + 1
    });
  }

  render() {
    return (
      <div className="Statistics">
        <h2>Statistics</h2>
          <p>
          Games Played: {this.state.gamesPlayed}
          </p>
          <p>
          Games Won: {this.state.gamesWon}
          </p>
          <p>
          Scores Distribution: {this.state.scores}
          </p>
      </div>
    );
  }
}

export default App;